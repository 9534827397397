.rcu-component svg {
  vertical-align: middle;
}

.rcu-icon.disabled .ij-icon.ij-icon-lg embed, .rcu-icon.disabled .ij-icon.ij-icon-lg svg {
    fill: #e1e6eb !important;
    cursor: default;
}

.rcu-icon.disabled .ij-icon.ij-icon-lg embed:hover, .rcu-icon.disabled .ij-icon.ij-icon-lg svg:hover {
    fill: #e1e6eb !important;
    cursor: default;
}

.rcu-icon .ij-icon.ij-icon-lg embed:hover, .rcu-icon .ij-icon.ij-icon-lg svg:hover {
    fill: rgb(240, 85, 58) !important;
    cursor: pointer;
}

.rcu-icon.rcu-icon-disabled .ij-icon.ij-icon-lg embed, .rcu-icon.rcu-icon-disabled .ij-icon.ij-icon-lg svg {
    fill: #e1e6eb !important;
    cursor: not-allowed;
}

.rcu-icon.rcu-icon-disabled .ij-icon.ij-icon-lg embed:hover, .rcu-icon.rcu-icon-disabled .ij-icon.ij-icon-lg svg:hover {
    fill: #e1e6eb !important;
    cursor: not-allowed;
}

.rcu-user-avatar {
    width: 24px;
    height: 24px;
    border-radius: 50%;
    margin-right: 5px;
    display: inline-block;
}

.rcu-user-row {
    cursor: pointer;
}

.user-icon-member {
    color: rgb(105, 117, 130);
}

.user-icon-member svg {
    fill: rgb(105, 117, 130);
}

.user-icon-admin {
    color: rgb(214, 54, 37);
}

.user-icon-admin svg {
    fill: rgb(214, 54, 37);
}

.rcu-checkbox-list {
    margin-bottom: 20px;
}

.rcu-checkbox-row {
    border: 1px solid #e1e6eb;
    border-bottom: 0px;
    padding: 20px;
}

.rcu-checkbox-list > .rcu-checkbox-row:last-child {
    border-bottom: 1px solid #e1e6eb;
}

.rcu-checkbox-list .rcu-radio {
    border: 1px solid #e1e6eb;
    border-bottom: 0px;
    padding: 20px;
    margin-bottom: 0px;
    margin-right: 0px;
}

.rcu-radio {
    margin-right: 0px !important;
}

.rcu-radio h5 {
    display:  inline;
}

.rcu-checkbox-row .checkbox {
    display: inline;
}

.rcu-checkbox-row:hover {
    background-color: #edf3f9;
    cursor: pointer;
}

.rcu-header {
    margin-bottom: 5px;
}

.rcu-component {
}

.rcu-principal-tooltip .tooltip-inner {
    max-width: 400px !important;
}
